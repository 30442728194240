<template>
  <base-section id="aboutourproduct">
    <v-row>
      <v-col class="d-flex justify-center">
        <h1 class="font-weight-black text-uppercase">
          Instaladores oficiales
        </h1>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col class="d-flex align-center" cols="10">
        <v-img
          contain
          width="50px"
          :src="require('@/assets/onventanaslogo.gif')"
        />
        <v-img
          contain
          width="50px"
          :src="require('@/assets/kommerlinglogo.png')"
        />
        <v-img contain width="50px" :src="require('@/assets/recarlogo.png')" />
      </v-col>
    </v-row>
    <v-container>
      <base-section-heading color="grey lighten-2" title="Tipos de Ventanas" />
      <v-row>
        <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
          <base-info-card v-bind="card" />
          <v-img :src="card.url" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionAboutOurProduct",
  data: () => ({
    cards: [
      {
        title: "Oscilobatiente",
        // subtitle: "Vidrio",
        text: "Se compone de un marco fijo y una o dos hojas móviles",
        callout: "1",
        url:
          "https://www.kommerling.es/sites/default/files/opening_types/01-ventana-oscilobatiente.gif"
      },
      {
        title: "Abatible con eje horizontal",
        // subtitle: 'Subtitulo',
        text: "En la apertura, la hoja abre girando sobre un eje horizontal",
        callout: "2",
        url:
          "https://www.kommerling.es/sites/default/files/opening_types/02-ventana-abatible-eje-horiz.gif"
      },
      {
        title: "Abatible con eje vertical",
        // subtitle: 'Subtitulo',
        text:
          "en la apertura abatible de eje vertical la hoja abre girando sobre un eje vertical",
        callout: "3",
        url:
          "https://www.kommerling.es/sites/default/files/opening_types/03-ventana-abatible-eje-vert.gif"
      },
      {
        title: "Pivotante",
        // subtitle: 'Subtitulo',
        text:
          "También conocida como giratoria, en esta apertura la hoja gira alrededor de un eje central, habitualmente horizontal.",
        callout: "4",
        url:
          "https://www.kommerling.es/sites/default/files/opening_types/04-ventana-pivotante.gif"
      },
      {
        title: "Puerta corredera elevadora",
        // subtitle: 'Subtitulo',
        text:
          "Su apertura no ocupan espacio. Excepto para el funcionamiento de osciloparalela y corredera elevadora, los valores de aislamiento e impermeabilidad son generalmente inferiores a los sistemas deslizantes.",
        callout: "5",
        url: ""
      },
      {
        title: "Puertas Plegables",
        // subtitle: 'Subtitulo',
        text:
          "Las hojas se pliegan contra uno de los laterales del hueco. Se emplea habitualmente para puertas.",
        callout: "6",
        url: ""
      }
    ]
  })
};
</script>
